<template>
  <div v-show="showBackToTop" class="to-top" @click="scrollToTop"></div>
</template>

<script setup>
import {getCurrentInstance, ref} from "vue";
import {useEventListener} from "@utils/tool/event";

const showBackToTop = ref(false)
const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
}
//滚动到最底部的时候showBackToTop 为true
const onScroll = () => {
  const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
  const clientHeight = document.documentElement.clientHeight || document.body.clientHeight
  showBackToTop.value = scrollTop  > clientHeight
}
useEventListener(window, 'scroll', onScroll)
const {proxy} = getCurrentInstance();
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "topBtn",
});
</script>

<style lang="scss" scoped>
.to-top {
  position: fixed;
  bottom: 80px;
  right: $padl;
  width: 56px;
  height: 56px;
  background: url(~@img/shop/h5/top.png) center center no-repeat;
  background-size: contain;
  cursor: pointer;
  z-index: 11;
}
</style>
