<template>
  <img src="@img/shop/h5/top.png" alt="位元灵感" v-if="showBackToTop" class="h5-top-btn" @click="scrollToTop"/>
</template>

<script setup>
import {getCurrentInstance, ref} from "vue";
import {useEventListener} from "@utils/tool/event";
const {proxy} = getCurrentInstance();
const showBackToTop = ref(false)
const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
}
//滚动到最底部的时候showBackToTop 为true
const onScroll = () => {
  const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
  const clientHeight = document.documentElement.clientHeight || document.body.clientHeight
  showBackToTop.value = scrollTop  > clientHeight
}
useEventListener(window, 'scroll', onScroll)
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "h5TopBtn",
});
</script>

<style lang="scss" scoped>
.h5-top-btn {
  position: fixed;
  right: 0.32rem;
  bottom: 4.3rem;
  width: 0.8rem;
  height: 0.8rem;
  z-index: 11;
}
</style>
