<template>
  <div class="h5-menu-box" :class="modelValue?'active':''">
    <div class="h5-menu" >
      <el-icon class="close-icon" @click="closeMenu"><CloseBold /></el-icon>
      <ul class="h5-menu-content">
        <li class="item cur_p" @click="handleLink(item)" v-for="item in tabList" :key="item.id">{{item.name}}</li>
      </ul>
    </div>
    <h5-concat-us v-model="showConcat"/>
  </div>
</template>

<script setup>
import { toRefs , ref } from "vue";
import { useRouter,useRoute } from "vue-router";
import H5ConcatUs from "@views/shop/h5Components/h5ConcatUs/index.vue";
const showConcat = ref(false)
const router = useRouter()
const route = useRoute()
const props = defineProps({
  modelValue:{
    type:Boolean,
    default:false
  }
})
const tabList = [
  {
    name:"了解更多",
    id:1,
    link:'/shop/knowMore',
  },{
    name:"联系我们",
    id:2,
    link:'',
  },{
    name:"产品定价",
    id:3,
    link:'/shop/knowMore?goods=1',
  },
]
const  {modelValue} = toRefs(props)
const emit = defineEmits(['update:modelValue'])
const closeMenu = ()=>{
  emit('update:modelValue',false)
}
const handleLink = (e)=>{
  if(e.link){
    if (route.path === '/shop/knowMore' && e.id === 3) {
    let dom = document.getElementById('goods-info-introduce')
    dom.scrollIntoView({
      behavior: "smooth", // 定义动画过渡效果， "auto"或 "smooth" 。默认为 "auto"。
      block: "start", // 定义垂直方向的对齐， "start", "center", "end", 或 "nearest"。默认为 "start"。
      inline: "center", // 定义水平方向的对齐， "start", "center", "end", 或 "nearest"。默认为 "nearest"
    })
     emit('update:modelValue',false)
  } else {
    router.push(e.link)
  }

  }else{
    showConcat.value = true
  }
}
</script>

<style lang="scss" scoped>
.h5-menu-box{
  position: absolute;
  right: -100vw;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 999;
  @include transitionView(0.2s);
  display: flex;
  justify-content: flex-end;
  &.active{
    right: 0;
    background: rgba(0,0,0,0.3);
  }
}
.h5-menu{
  width: 80vw;
  padding: 13.24vh 0 0 13.5%;
  background: #F5F5F5;
  z-index: 999;
  .close-icon{
    position: absolute;
    top: 7.3vh;
    right: 4.4vw;
    font-size: 0.28rem;
    color: $shopFsColor;
  }
  .h5-menu-content{
    .item{
      margin-bottom: 4vh;
      font-size: 0.4rem;
      color: $shopFsColor;
    }
  }
}
</style>