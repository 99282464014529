<template>
  <popup v-model:show="modelValue"  :before-close="closePop" round>
    <div class="content" :class="type === 'phone'?'':'web'">
      <el-icon class="close-icon" @click="closePop">
        <CircleClose/>
      </el-icon>
      <video loop class="video-content" controls :src="videoUrl" ref="videoRefs"></video>
    </div>
  </popup>
</template>
<script setup>
import {ref, toRefs, watch} from "vue";
import {Popup} from "vant";

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false
  },
  videoUrl:{
    type:String,
    default:''
  },
  type: {
    type: String,
    default: 'phone'
  }
})
const {modelValue, videoUrl, type} = toRefs(props)
const emit = defineEmits(['update:modelValue'])
const videoRefs = ref(null)
const closePop = ()=>{
  emit('update:modelValue',false)
}
watch(()=>modelValue.value,(val)=>{
  setTimeout(()=>{
    if(val && videoRefs.value){
      videoRefs.value.play()
    }else if(!val && videoRefs.value){
      videoRefs.value.pause()
      //时间重置
      videoRefs.value.currentTime = 0
    }
  },100)
})
</script>
<script>
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'h5VideoPlayer',
});
</script>

<style lang="scss" scoped>
.content{
  position: relative;
  width: 80vw;
  height: 144vw;

  &.web {
    width: 80vw;
    height: 45vw;
  }
  .close-icon{
    position: absolute;
    top: 0.2rem;
    right: 0.2rem;
    font-size: 0.8rem;
    z-index: 10;
  }
  .video-content{
  //视频比例为手机标准比例
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
}

</style>