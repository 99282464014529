<template>
  <div class="footer">
    <div class="list">
      <div class="single cur_p" v-for="item in tabLink" :key="item.id" @click="go(item)">{{ item.name }}</div>
    </div>
    <div class="copy-right"><a href="https://beian.miit.gov.cn/#/Integrated/index">{{getRecordNumber()}}</a></div>
    <concat-d-ia v-model="showDia" value="请添加微信号"></concat-d-ia>
  </div>
</template>

<script setup>
import {getCurrentInstance, ref} from "vue";
import {useRouter} from "vue-router";
import ConcatDIa from "@views/shop/components/concatDIa/index.vue";
import {getRecordNumber} from "@utils/tool.js"
import useBaseInfo from "@utils/tool/shop/useBaseInfo";

const {goBuy} = useBaseInfo()
const router = useRouter()
const showDia = ref(false)
const tabLink = [
  {
    name: '了解更多',
    id: 1,
    link: '/shop/knowMore'
  },
  {
    name: '购买套餐',
    id: 2,
    link: '/shop/order/buy/1'
  },
  {
    name: '联系我们',
    id: 3,
  },
]
const {proxy} = getCurrentInstance();
const go = (e) => {
  if (e.id === 3) {
    showDia.value = true
  } else if (e.id === 2) {
    goBuy()
  } else {
    router.push({
      path: e.link
    })
  }
}
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "shopFooter",
});
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  height: 280px;
  padding: 100px $padl 0;

  .list {
    display: flex;
    align-items: center;
    font-size: 14px;

    .single {
      line-height: 1;
      color: $fsColor;
      margin-right: 80px;
    }
  }

  .copy-right {
    font-size: 14px;
    color: #C4C4CC;
    margin-top: 40px;

    a {
      color: #C4C4CC;

      &:hover {
        color: $shopFsColor;
      }
    }
  }
}
</style>
