<template>
  <footer>
    <div class="footer-text"><a href="https://beian.miit.gov.cn/#/Integrated/index">{{getRecordNumber()}}</a></div>
  </footer>
</template>

<script setup>
import { getRecordNumber } from "@utils/tool.js"
</script>

<style lang="scss" scoped>
footer{
  height: 2rem;
  background: #F5F5F5;
  line-height: 2rem;
  padding: 0 0.32rem;
  .footer-text{
    a{
      color: #C4C4CC;
      font-size: 0.22rem;
      text-decoration: none;
    }
  }
}
</style>