<template>
  <img :src="imgUrl" alt="位元灵感" class="h5-menu-btn cur_p" @click="handleClickShowMenu" v-bind="attrs"/>
  <h5-menu v-model="showMenu" />
</template>

<script setup>
import {ref, toRefs, useAttrs, computed} from "vue";
import H5Menu from './menu.vue';
const props = defineProps({
  type:{
    type:String,
    default:'index'

  },
  scrollTop: {
    type: Number,
    default: 0,
  }
})
const showMenu = ref(false)
const attrs = useAttrs()
const {type, scrollTop} = toRefs(props);
const handleClickShowMenu = ()=>{
  showMenu.value = !showMenu.value
}
const imgUrl = computed(() => {
  if(type.value === 'index'){
    if (scrollTop.value < 1) {
      return require('@img/shop/h5/h5-menu.png');
    } else {
      return require('@img/shop/h5/h5-menu.png');
    }
  }else{
    if (scrollTop.value < 1) {
      return require('@img/shop/h5/h5-menu2.png');
    } else {
      return require('@img/shop/h5/h5-menu.png');
    }
  }
})
</script>

<style lang="scss" scoped>
.h5-menu-btn{
  width: 0.4rem;
  height: 0.28rem;
}
</style>