<template>
  <popup v-model:show="modelValue" :style="{ height: '90%' }" :before-close="closePop" round position="bottom">
   <div class="pop-content-main">
     <div class="title-content dp_f">
       <div class="title">联系我们</div>
       <icon name="cross" class="close-icon" @click="closePop"/>
     </div>
     <div class="pop-content">
       <div class="wechat-content">
         <div class="title">
           <img src="@img/shop/h5/concat-wechat.png" alt="位元灵感" />
           <div class="text">企业微信</div>
         </div>
         <div class="wechat-img">
           <img src="@img/shop/index/wechatPic.jpg" alt="位元灵感" />
         </div>
         <div class="sub-text">请扫描添加客服微信</div>
       </div>
       <div class="form-content">
         <div class="title">请留下您的联系方式<span>客服稍后与您联系</span></div>
          <div class="form-item dp_f">
            <div class="key"><span class="red">*</span>联系电话</div>
            <el-input type="tel" maxlength="11" v-model="formData.phone" class="form-input"  />
          </div>
          <div class="form-item dp_f">
            <div class="key"><span class="red">*</span>如何称呼</div>
            <el-input type="text" maxlength="50" v-model="formData.name" class="form-input" />
          </div>
          <div class="form-item dp_f">
            <div class="key">微信</div>
            <el-input type="text" maxlength="50" v-model="formData.wechat" class="form-input" />
          </div>
          <div class="button-box">
            <div class="wy-button submit primary" @click="handleClickSubmit">提交</div>
          </div>
       </div>
     </div>
   </div>
  </popup>
</template>

<script setup>
import {getCurrentInstance, reactive, toRefs} from "vue";
import {Icon, Popup, showToast} from "vant";
import {contactUs} from "@utils/api/shop";

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false
  }
})
const formData = reactive({
  phone: '',
  name:'',
  wechat:''
})
const {modelValue} = toRefs(props)
const emit = defineEmits(['update:modelValue'])
const {proxy} = getCurrentInstance();
const closePop = ()=>{
  emit('update:modelValue',false)
}
const handleClickSubmit=()=>{
  if(!formData.phone){
    showToast('请输入联系电话')
    return
  }
  if(!/^1[3456789]\d{9}$/.test(formData.phone)){
    showToast('请输入正确的联系电话')
    return
  }
  if(!formData.name){
    showToast('请输入称呼')
    return
  }
  contactUs({
    mobile: formData.phone,
    wechat: formData.wechat,
    name: formData.name
  }).then((res) => {
    if (res.code !== 0) return showToast(res.msg)
    showToast('提交成功')
    formData.phone = ""
    formData.wechat = ""
    formData.name = ""
    emit('update:modelValue',false)
})
}
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "h5ConcatUs",
});
</script>

<style lang="scss"  src="./index.scss" scoped>

</style>
