<template>
  <header class="head dp_f" :style="style">
    <h5-logo :type="type" :scroll-top="scrollTop"/>
    <h5-menu-btn :type="props.type" :scroll-top="scrollTop"/>
  </header>
</template>

<script setup>
import {getCurrentInstance,computed ,ref} from "vue";
import H5Logo from "@views/shop/h5Components/h5Logo/index.vue";
import H5MenuBtn from '@views/shop/h5Components/h5MenuBtn/index.vue'
import {useEventListener} from "@utils/tool/event";
const props = defineProps({
  type:String,
  default:'index'
})
const {proxy} = getCurrentInstance();
let scrollTop = ref(0);
const scroll = () => {
  scrollTop.value = (document.documentElement.scrollTop / 1000);
};
const style = computed(() => {
  return {
    backgroundColor: `rgba(56,89,255,${scrollTop.value})`,
  };
});
useEventListener(window, "scroll", scroll);
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "h5Header",
});
</script>

<style lang="scss" scoped>
.head {
  height: 0.46rem;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  padding: 0.5rem;
  box-sizing: border-box;
  color: #fff;
  z-index: 999;
}
</style>
